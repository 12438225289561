<template>
    <section class="mission site-max-width">
        <IntroTitles :block="introBlock" />
        <div class="grid-items col-4">
            <article class="item" v-for="(item, i) in gridIcones" :key="'gi' + i" data-inview="fadeInUp" :data-delay="200 * i">
                <img :src="item.image.url" :alt="item.image.titre">
                <h4 class="title smaller-title">
                    {{ item.titre }}
                </h4>
                <p class="small-text">
                    <span v-html="item.texte"></span>
                </p>
            </article>
        </div>
    </section>
</template>

<script>
import IntroTitles from '@/components/IntroTitles.vue'

export default {
    name: 'Mission',

    components: {
        IntroTitles,
    },

    props: {
        introBlock: {
            type: Object,
            required: true,
        },
        gridIcones: {
            type: Array,
            required: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
